import React from "react";
import Hero from "../Components/Home/Hero";
import Events from "../Components/Home/Events";
import Products from "../Components/Home/Products";
import Participants from "../Components/Home/Participants";

function Home() {
  return (
    <>
      <Hero />
      <Events />
      <Products />
      <Participants />
    </>
  );
}

export default Home;
