import React from "react";
import ImageHero from "../../assets/img/banner/Home_Banner Image.png";
import BgElement from "../../assets/img/elements/Asset 2@2x 1.webp";
import BgElement1 from "../../assets/img/elements/Asset 2@2x 2 (1).webp";

function Hero() {
	return (
		<>
			<div className="flex-col-reverse xl:flex-row flex justify-between items-center h-[100%] md:h-[800px] lg:h-[800px] xl:h-[600px] bg-gradient-to-l from-emerald-400 to-blue-900 overflow-x-hidden">
				<div className="z-[1] px-5 xl:px-24 master flex flex-col justify-center align-middle ">
					<div className="f-1 text-stone-50 text-[30px] md:text-[45px] font-extrabold md:leading-[65.10px]">
						Developing Young Digital Talents across Indonesia
					</div>
					<div className="md:w-[720px] md:h-[155px] text-stone-50 text-[19px] md:text-[25px] font-normal md:leading-[45px]">
						Unlock new success with experiential learning. Enhance your
						professional and digital skills. Join us today!
					</div>
				</div>
				<img
					className="z-[1] h-[300px] md:h-[400px] lg:[600px] xl:h-full md:mr-24"
					src={ImageHero}
					alt=""
				/>
				<img src={BgElement} className="absolute h-full  opacity-30" alt="" />
				<img
					src={BgElement1}
					className="right-0 w-[80%] absolute  opacity-30"
					alt=""
				/>
			</div>
		</>
	);
}

export default Hero;
