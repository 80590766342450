function WhyChooseUs() {
	return (
		<>
			<div className="bg-gradient-to-l from-emerald-400 to-blue-900 pt-20 md:pt-0">
				<div className="p-5 card md:p-10 px-5 lg:px-24 rounded-t-[50px] md:rounded-t-[117px] bg-white">
					<div className="text-center f-1 text-teal-600 text-3xl md:text-[50px] font-extrabold leading-[65.10px]">
						Why Choose Us
					</div>

					<div className="flex flex-col xl:flex-row mt-5">
						<div className="md:space-x-3 my-3 mt-5 lg:w-1/3">
							<div className="mx-3 h-full">
								<div className="h-full flex-col bg-[#0D2F69] py-6 px-4 rounded-[45px] md:rounded-[65px] justify-center items-center mx-auto md:min-h-[21rem] min-h-[16rem]">
									<h4 className="text-2xl md:text-3xl f-1 text-white font-extrabold py-3 mx-3 pl-3 pr-6 text-center md:text-start">
										Expertise and 
										Practical Knowledge
									</h4>
									<h2 className="text-sm md:text-xl font-4 px-2 mx-3 text-left mt-4">
										Our trainers are seasoned experts and experienced practitioners who provide hands-on, real-world learning experiences. 
										This ensures that participants gain practical skills directly applicable to their work.
									</h2>
								</div>
							</div>
						</div>

						<div className="md:space-x-3 my-3 mt-5 lg:w-1/3">
							<div className="mx-3 h-full">
								<div className="flex-col h-full bg-[#2C77A3] py-6 px-4 rounded-[45px] md:rounded-[65px] justify-center items-center mx-auto md:min-h-[21rem] min-h-[16rem]">
									<h4 className="text-2xl md:text-3xl f-1 text-white font-extrabold py-3 mx-3 pl-3 pr-6 text-center md:text-start">
										Up-to-Date and
										Curated Materials
										
									</h4>
									<h2 className="text-sm md:text-xl font-4 px-2 mx-3 text-left mt-4">
										We continuously update and refine our learning materials to keep pace with industry trends and advancements. 
										This commitment guarantees that learners receive the most current and relevant information.
									</h2>
								</div>
							</div>
						</div>

						<div className="md:space-x-3 my-3 mt-5 lg:w-1/3 h-full">
							<div className="mx-3 h-full">
								<div className="flex-col w-full h-full bg-[#26ACAD] py-12 px-4 rounded-[45px] md:rounded-[65px] justify-center items-center mx-auto md:min-h-[21rem] min-h-[16rem]">
									<h4 className="text-2xl md:text-3xl f-1 text-white font-extrabold py-3 mx-3 pl-3 pr-6 text-center md:text-start">
										Long-Term 
										Impact
									</h4>
									<h2 className="text-sm md:text-xl font-4 px-2 mx-3 text-left mt-4">
										Our programs are designed to sustain learning outcomes over time, fostering continuous growth. 
										Graduates leave with a strong foundation in both technical and soft skills, 
										making them well-prepared for the challenges of the digital world.
									</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default WhyChooseUs;
