import ImageNews from '../../assets/img/gallery/Training/MicrosoftTeams-image (62) (2).png'

export const data = [
    {
        id: 1,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'News 1',
        category: 'Info',
    },
    {
        id: 2,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'News 2',
        category: 'Info',
    },
    {
        id: 3,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'News 3',
        category: 'Info',
    },
    {
        id: 4,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'News 4',
        category: 'Info',
    },
    {
        id: 5,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'News 5',
        category: 'Info',
    },
    {
        id: 6,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'News 6',
        category: 'Info',
    },
    {
        id: 7,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
        category: 'News',
    },
    {
        id: 8,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
        category: 'News',
    },
    {
        id: 9,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
        category: 'News',
    },
    {
        id: 10,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
        category: 'News',
    },
    {
        id: 11,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
        category: 'News',
    },
    {
        id: 12,
        date: new Date('2023-09-23').toLocaleDateString('en-us', {year: 'numeric', month: 'short', day: 'numeric',}),
        image: ImageNews,
        title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
        category: 'News',
    },
]

export default {data}